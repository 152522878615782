<template>
  <div class="labs-page">
    <v-card color="#fff" flat>
      <v-card-title class="pt-6 pb-9 px-4">
        <h3>{{ warningHeader }}</h3>
      </v-card-title>
    </v-card>
    <v-card color="#1F6070" flat>
      <v-card-title>
        <h1>Your results</h1>
      </v-card-title>
    </v-card>
    <div class="pt-6 px-4">
      <v-card
        v-for="item in categories"
        :key="item"
        color="#fff"
        flat
        class="mb-4 mx-4"
        style="border-radius: 16px"
        @click="goToLink(item)"
      >
        <v-card-title>
          <h2>{{ item }}</h2>
          <v-spacer></v-spacer>
          <img
            width="15px"
            height="30px"
            :src="require('../../assets/svg/right.svg')"
            class="pr-2"
          />
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    warningHeader:
      'Your care team will call you with any beta HCG pregnancy test and HIV test results. All other laboratory test results will appear below after being reviewed by your provider.'
  }),
  computed: {
    categories() {
      return ['Blood test results']; //, 'Embryology results', 'Semen analysis'
    }
  },
  methods: {
    goToLink(item) {
      if (item === 'Blood test results') {
        this.$router.push({ path: '/labs/blood-test' });
      }

      if (this.title === 'Embryology results') {
        this.$router.push({ path: '/labs/embryology' });
      }

      if (this.title === 'Semen analysis') {
        this.$router.push({ path: '/labs/semen-analysis' });
      }
    }
  }
};
</script>
<style lang="scss">
.labs-page {
  .v-card__title {
    h1 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      white-space: pre-wrap;
      word-break: break-word;
    }
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
</style>
